import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import ReactCompareImage from 'react-compare-image';
import { toast } from 'react-toastify';

import { Button, IntroPage } from 'components';
import { addToCart } from 'store/cartSlice';
import { isEmptyObject } from 'utils/validData';
import { isGoodInCart } from 'utils/checkData';
import { currencyFormat } from 'utils/intlFormat';
import { priceWithDiscount } from 'utils/numberFormat';
import PRESETS from 'constants/presets';
import { MESSAGES, ROUTER_PATHS } from 'constants/enums';
import { ReactComponent as MastercardIcon } from 'assets/images/mastercard.svg';
import { ReactComponent as VisaIcon } from 'assets/images/visa.svg';

import s from './s.module.scss';

const PresetBuy = () => {
  const dispatch = useDispatch();
  const cart = useSelector(({ cartSlice }) => cartSlice.cart);
  const { id } = useParams();
  const navigate = useNavigate();

  const isPresetInCart = isGoodInCart(id, cart);

  const [preset, setPreset] = useState({});
  const [goodCount, setGoodCount] = useState(1);

  const handlePlusCount = () => {
    if (goodCount > 98) {
      return;
    }
    setGoodCount((currValue) => currValue + 1);
  };

  const handleMinusCount = () => {
    if (goodCount < 2) {
      return;
    }
    setGoodCount((currValue) => currValue - 1);
  };

  const handleSetPreset = () => {
    const selectedPreset = PRESETS.find((item) => item.id === id);

    if (!id || !selectedPreset) {
      toast.warning(MESSAGES.PRESET_NOT_FOUND);
      navigate(ROUTER_PATHS.SHOP, { replace: true });
    }

    setPreset(selectedPreset);
  };

  const handleAddToCart = () => {
    if (isPresetInCart) {
      navigate(ROUTER_PATHS.CART);
      return;
    }
    dispatch(
      addToCart({
        id,
        count: goodCount,
      })
    );
    toast.info('Preset successfully added to cart');
  };

  useEffect(() => {
    handleSetPreset();
  }, [id]);

  if (isEmptyObject(preset)) {
    return null;
  }
  return (
    <>
      <IntroPage title={preset.name} image={preset.introImage} />
      <div className="main_container mb100">
        <div className={s.preset_wrapper}>
          <div className={s.images_wrapper}>
            <img src={preset.mainImage} alt="main" className={s.main_image} />
            <div className={s.images}>
              {preset.images.map(({ after, before }) => (
                <div key={after} className={s.compare_image_wrapper}>
                  <ReactCompareImage
                    leftImage={after}
                    rightImage={before}
                    handleSize={30}
                    sliderLineWidth={1}
                    sliderLineColor="rgba(255, 255, 255, 0.6)"
                  />
                </div>
              ))}
            </div>
          </div>
          <div className={s.info_wrapper}>
            <p className={s.name}>{preset.name}</p>
            <p className={s.price}>
              {preset?.discount ? <span className={s.old_price}>{currencyFormat(preset.price)}</span> : null}
              {currencyFormat(priceWithDiscount(preset.price, preset.discount))}
            </p>
            <div className={s.cart_wrapper}>
              {!isPresetInCart ? (
                <div className={s.count}>
                  <button type="button" className={s.count_btn} onClick={handleMinusCount}>
                    -
                  </button>
                  <p className={s.count_view}>{goodCount}</p>
                  <button type="button" className={s.count_btn} onClick={handlePlusCount}>
                    +
                  </button>
                </div>
              ) : null}
              <div className={s.add_cart_btn}>
                <Button
                  label={isPresetInCart ? 'GO TO CART' : 'ADD TO CART'}
                  round="block"
                  size="lg"
                  theme={isPresetInCart ? 'blueBlack' : 'lightBlue'}
                  onClick={handleAddToCart}
                />
              </div>
              <div className={s.secure}>
                <p className={s.secure_label}>Secure Checkout With</p>
                <div className={s.secure_wrapper}>
                  <VisaIcon /> <MastercardIcon />
                </div>
              </div>
            </div>
            <div className={s.desc}>{preset.description}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PresetBuy;
