import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { useForm } from 'hooks';
import { Input, Button } from 'components';
import { setLoader } from 'store/loaderSlice';
import { randomInteger } from 'utils/randomize';
import { CONTACT_US_SCHEME } from 'constants/validateForm';
import { INITIAL_CONTACT_FORM } from './const';

import s from './s.module.scss';

const ContactUs = () => {
  const dispatch = useDispatch();

  const { form, setValue, errors, handleFormSubmit, resetValidation, reset } = useForm(
    INITIAL_CONTACT_FORM,
    CONTACT_US_SCHEME
  );

  const handleSubmit = () => {
    dispatch(setLoader(true));
    setTimeout(() => {
      dispatch(setLoader(false));
      toast.info(`Thank you for contacting us. Answer will be sent to your email: ${form.email}`);
      resetValidation();
      reset();
    }, randomInteger(1000, 3000));
  };

  return (
    <div className={s.block}>
      <div className="main_container">
        <h3 className={s.title}>Contact us</h3>
        <p className={s.text}>Do you have any questions or want to know more? Write to us, we will help you.</p>
        <form className={s.form} onSubmit={(e) => handleFormSubmit(e, handleSubmit)} noValidate>
          <Input
            placeholder="Enter your name"
            name="name"
            onChange={setValue}
            value={form.name}
            error={errors?.name?.at(0)}
            theme="whiteTrans"
          />
          <Input
            type="email"
            placeholder="Enter your email"
            name="email"
            onChange={setValue}
            value={form.email}
            error={errors?.email?.at(0)}
            theme="whiteTrans"
          />
          <Input
            placeholder="Enter your question"
            name="question"
            onChange={setValue}
            value={form.question}
            error={errors?.question?.at(0)}
            theme="whiteTrans"
            multiline
          />
          <Button type="submit" label="SEND" round="block" theme="whiteBlue" />
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
