import { IntroPage } from 'components';
import { CONTACTS } from 'constants/enums';
import IMAGE from 'assets/images';

const PrivacyPolicy = () => {
  return (
    <div className="smooth_enter_an">
      <IntroPage title="Privacy Policy" image={IMAGE.towfiqu} />
      <div className="main_container page_content mb60">
        <p>
          This Privacy Policy describes how your personal information is collected, used, and shared when you visit or
          make a purchase from{' '}
          <a href={CONTACTS.SITE_URL} target="_blank" rel="noopener noreferrer">
            {CONTACTS.SITE_URL}
          </a>{' '}
          (the “Site”).
        </p>
        <h3>OVERVIEW</h3>
        <p>
          When you purchase something from our store, as part of the buying and selling process, we collect the personal
          information you give us such as your name, address and email address to form your account. We also track data
          collected from your time spent browsing the website. When you browse our store, we also automatically receive
          your computer’s internet protocol (IP) address in order to provide us with information that helps us learn
          about your browser and operating system. Email marketing (if applicable): With your prior permission only, we
          may send you emails about our store, new products and other updates. You will only receive emails of this
          nature if you have previously signed up and consented to the{' '}
          <b className="col_blue_wood">{CONTACTS.APP_NAME}</b> newsletter/mailing list. You can opt out of receiving
          messages from <b className="col_blue_wood">{CONTACTS.APP_NAME}</b> at any time by clicking the link in the
          email. Alternatively, if after you opt-in, you change your mind, you may withdraw your consent for us to
          contact you, for the continued collection, use or disclosure of your information, at anytime, by contacting us
          at <a href={`mailto:${CONTACTS.EMAIL}`}>{CONTACTS.EMAIL}</a>. If you have not consented to receive email
          marketing but have purchased a product from <b className="col_blue_wood">{CONTACTS.APP_NAME}</b>, you will
          only be contacted regarding your product (including but not limited to: Updated product downloads, bug fixes,
          changes to the product and anything else related to your purchase). You consent to the{' '}
          <b className="col_blue_wood">{CONTACTS.APP_NAME}</b> Terms of Service upon placing an order and completing the
          Checkout process. You may email us directly at <a href={`mailto:${CONTACTS.EMAIL}`}>{CONTACTS.EMAIL}</a> to
          request we remove your full information from our system at any time.
        </p>
        <h3>PRIVACY POLICY IN FULL</h3>
        <h6>PERSONAL INFORMATION WE AUTOMATICALLY COLLECT</h6>
        <p>
          When you visit the Site, we automatically collect certain information about your device, including information
          about your web browser, IP address, time zone, and some of the cookies that are installed on your device.
          Additionally, as you browse the Site, we collect information about the individual web pages or products that
          you view, what websites or search terms referred you to the Site, and information about how you interact with
          the Site. We refer to this automatically-collected information as “Device Information”. We can only collect
          Device Information automatically if this is enabled on your browser.
        </p>
        <p>We collect Device Information using the following technologies:</p>
        <ul>
          <li>
            “Cookies” are data files that are placed on your device or computer and often include an anonymous unique
            identifier. For more information about cookies, and how to disable cookies, visit{' '}
            <a href="http://www.allaboutcookies.org" target="_blank" rel="noopener noreferrer">
              http://www.allaboutcookies.org
            </a>
          </li>
        </ul>
        <p>
          Additionally when you make a purchase through the website, we collect and store certain information from you,
          including your name, billing address, shipping address, email address, and phone number. We refer to this
          information as “Order Information” and this can be removed from your account if required. Our secure payment
          gateways will store payment information (including credit card numbers and PayPal information) which we do not
          have access to directly. Your purchase transaction data is stored only as long as is necessary to complete
          your purchase transaction. After that is complete, your purchase transaction information is deleted.
        </p>
        <h6>HOW DO WE USE YOUR PERSONAL INFORMATION?</h6>
        <p>
          We use the Order Information that we collect generally to fulfil any orders placed through the Site (including
          processing your payment information, arranging for shipping, and providing you with invoices and/or order
          confirmations). Additionally, we use this Order Information to:
        </p>
        <ul>
          <li>Communicate with you regarding your order;</li>
          <li>Screen our orders for potential risk or fraud;</li>
          <li>
            When in line with the preferences you have shared with us, provide you with information or advertising
            relating to our products or services.
          </li>
          <li>Send you updated versions of your purchase if applicable.</li>
        </ul>
        <p>
          We use the Device Information that we collect to help us screen for potential risk and fraud (in particular,
          your IP address), and more generally to improve and optimize our Site (for example, by generating analytics
          about how our customers browse and interact with the Site, and to assess the success of our marketing and
          advertising campaigns). Device Information may also be used in retargeting, social media adverts and other
          means of advertising to our target audience.
        </p>
        <h6>SHARING YOUR PERSONAL INFORMATION</h6>
        <h6>THIRD-PARTY SERVICES</h6>
        <p>
          In general, the third-party providers used by us will only collect, use and disclose your information to the
          extent necessary to allow them to perform the services they provide to us.
        </p>
        <p>
          However, certain third-party service providers, such as payment gateways and other payment transaction
          processors, have their own privacy policies in respect to the information we are required to provide to them
          for your purchase-related transactions.
        </p>
        <p>
          For these providers, we recommend that you read their privacy policies so you can understand the manner in
          which your personal information will be handled by these providers.
        </p>
        <p>
          In particular, remember that certain providers may be located in or have facilities that are located a
          different jurisdiction than either you or us. So if you elect to proceed with a transaction that involves the
          services of a third-party service provider, then your information may become subject to the laws of the
          jurisdiction(s) in which that service provider or its facilities are located.
        </p>
        <p>
          As an example, if you are located in Canada and your transaction is processed by a payment gateway located in
          the United States, then your personal information used in completing that transaction may be subject to
          disclosure under United States legislation, including the Patriot Act.
        </p>
        <p>
          Once you leave our store’s website or are redirected to a third-party website or application, you are no
          longer governed by this Privacy Policy or our website’s Terms of Service.
        </p>
        <h6>Links</h6>
        <p>
          When you click on links on our store, they may direct you away from our site. We are not responsible for the
          privacy practices of other sites and encourage you to read their privacy statements.
        </p>
        <h6>DO NOT TRACK</h6>
        <p>
          Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track
          signal from your browser.
        </p>
        <h6>YOUR RIGHTS</h6>
        <p>
          If you are a European resident, you have the right to access personal information we hold about you and to ask
          that your personal information be corrected, updated, or deleted. If you would like to exercise this right,
          please contact us through the contact information below.
        </p>
        <p>
          Additionally, if you are a European resident we note that we are processing your information in order to
          fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise to
          pursue our legitimate business interests listed above. Additionally, please note that your information will be
          transferred outside of Europe, including to Canada and the United States.
        </p>
        <h6>DATA RETENTION</h6>
        <p>
          When you place an order through the Site, we will maintain your Order Information for our records unless and
          until you ask us to delete this information.
        </p>
        <h6>CHANGES</h6>
        <p>
          We may update this privacy policy from time to time in order to reflect, for example, changes to our practices
          or for other operational, legal or regulatory reasons.
        </p>
        <h6>GOVERNING LAW CLAUSE</h6>
        <p>
          This Privacy Policy, and any dispute, controversy, proceedings or claim of whatever nature arising out of or
          in any way relating to this Privacy Policy or its formation (including any non-contractual disputes or
          claims), shall be governed by and construed in accordance with English law.
        </p>
        <h6>CONTACT US</h6>
        <p>
          For more information about our privacy practices, if you have questions, or if you would like to make a
          complaint, please contact us by e-mail at <a href={`mailto:${CONTACTS.EMAIL}`}>{CONTACTS.EMAIL}</a>.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
