/* eslint-disable react/no-unescaped-entities */
import IMAGE from 'assets/images/articles';

const BLOG = [
  {
    id: '73722ed9-b025-455a-ac2f-02a1c00d1bdc',
    name: 'HOW TO INSTALL LIGHTROOM PRESETS',
    desc: 'Welcome to Pretty Presets for Lightroom! We have the best Lightroom preset tutorial below. These basic instructions will teach you How to Install Lightroom Presets into Adobe Lightroom in just 2-3 minutes. We`ll have you using your gorgeous new presets in no time!',
    image: IMAGE.main1,
    content: (
      <>
        <p>
          Welcome to Pretty Presets for Lightroom! We have the best Lightroom preset tutorial below. These basic
          instructions will teach you How to Install Lightroom Presets into Adobe Lightroom in just 2-3 minutes. We`ll
          have you using your gorgeous new presets in no time!
        </p>
        <p>
          First, let`s establish which version of Lightroom you are currently using. There are so many versions of
          Lightroom (with almost the same name!) as well as different preset file types.
        </p>
        <p>I know it can be confusing, but that is why we are here to help you!</p>
        <p>
          To start, make sure you know which version of Lightroom you are using. If you don`t know, click "Help" at the
          top of your Lightroom dashboard and then click "System Info" to find out!
        </p>
        <h3>1. HOW TO INSTALL LIGHTROOM PRESETS IN:</h3>
        <h4>LIGHTROOM CLASSIC VERSION 7.3+ (.XMP FILES)</h4>
        <p>
          <b>Step 1.</b> Download your Lightroom Presets from Pretty Presets. The presets will come in a .zip file.
        </p>
        <p>
          <b>Step 2.</b> Navigate to the Develop Module in Lightroom and click on{' '}
          <b>File &gt; Import Develop Profiles and Presets</b> (see image below).
        </p>
        <img src={IMAGE.a1_1} alt="article" />
        <p>
          <b>Step 3. </b>Next, you will need to navigate to the <b>ZIPPED preset file</b> you downloaded. If you have
          already unzipped the download, navigate to the folder that holds the XMP files.
        </p>
        <p>
          <b>Step 4. </b>You're DONE! To apply them, open the Presets Panel on the left-side of your screen and use the
          small triangle to open the folder. Click a preset in the folder to apply it.
        </p>
        <p>
          <b>Lightroom Classic Quick Install Method: </b>Open Lightroom Classic and click on the small “+” at the top of
          the Presets Panel. Choose “Import Presets” from the opens that open. Navigate to the ZIPPED preset file you
          downloaded. The presets will automatically install!
        </p>
        <img src={IMAGE.a1_2} alt="article" />
        <h3>2. HOW TO INSTALL LIGHTROOM PRESETS IN:</h3>
        <h4>LIGHTROOM CC DESKTOP VERSION (.XMP FILES)</h4>
        <p>
          <b>Step 1.</b> Download your Lightroom Presets from Pretty Presets. The presets will come in a .zip file.
        </p>
        <p>
          <b>Step 2.</b> Open Lightroom CC and click on any image.
        </p>
        <p>
          <b>Step 3.</b> Go to <b>File&gt;Import Profiles and Presets</b> (see image below).
        </p>
        <img src={IMAGE.a1_3} alt="article" />
        <p>
          <b>Step 4.</b> Next, you will need to navigate to the <b>ZIPPED preset file</b> you downloaded. If you have
          already unzipped the download, navigate to the folder that holds the XMP files.
        </p>
        <p>
          <b>Step 5.</b> You're DONE!! To apply them, open the Presets Panel on the right-side of your screen and use
          the small triangle to open the folder. Click a preset in the folder to apply it.
        </p>
        <h3>3. HOW TO INSTALL LIGHTROOM PRESETS IN:</h3>
        <h4>LIGHTROOM 4, 5, 6 & LIGHTROOM CLASSIC VERSION 7.2 AND EARLIER (.LRTEMPLATE FILES)</h4>
        <p>
          <b>Step 1.</b> <b> On a PC</b>, go into Lightroom and click on <b>Edit</b> at the top (next to File), then
          click on Preferences. <b>On a Mac</b>, click on the <b>Lightroom tab</b> (to the left of File), then click on
          Preferences (see image below).
        </p>
        <img src={IMAGE.a1_4} alt="article" />
        <p>
          <b>Step 2.</b> There will be a new screen that pulls up with several tabs at the top. Click on <b>Presets</b>{' '}
          (the second tab).
        </p>
        <img src={IMAGE.a1_5} alt="article" />
        <p>
          <b>Step 3.</b> Click on the box titled, <b>Show Lightroom Presets Folder</b> if you are on an older version of
          Lightroom. If you are on a newer version of Lightroom Classic, click on the{' '}
          <b>Show All Other Lightroom Presets</b> box.
        </p>
        <p>
          <b>Step 4.</b> Double click on <b>Lightroom</b>.
        </p>
        <img src={IMAGE.a1_6} alt="article" />
        <p>
          <b>Step 5.</b> Next double click on <b>Develop Presets Folder</b>.
        </p>
        <p>
          <b>Step 6.</b> Copy the contents of the Pretty Presets Folder, found in your download, into the{' '}
          <b>“Develop Presets”</b> folder.
        </p>
        <img src={IMAGE.a1_7} alt="article" />
        <p>
          <b>Step 7.</b> Finally, restart Lightroom and you’re DONE! Your presets are now installed!! To apply them,
          open the Presets panel on the left-side of your screen and use the small triangle to open the folder. Click a
          preset in the folder to apply it.
        </p>
      </>
    ),
  },
  {
    id: '7cf232eb-1e14-4668-bc4b-b8b1f7369644',
    name: 'HOW TO INSTALL PRESETS IN THE FREE LIGHTROOM MOBILE APP',
    desc: 'We waited what seemed like forever to get the ability to use presets in Lightroom Mobile.  And we had to wait even longer to give you the ability to use Lightroom presets in the free version of Lightroom Mobile!',
    image: IMAGE.main2,
    content: (
      <>
        <p>
          We waited what seemed like forever to get the ability to use presets in Lightroom Mobile. And we had to wait
          even longer to give you the ability to use Lightroom presets in the free version of Lightroom Mobile!
        </p>
        <h3>STEP 1: UNZIP THE FILES</h3>
        <p>
          The first thing you will need to do is unzip the folder of presets that you downloaded.
          <b> The easiest way to do this is on a computer.</b>
        </p>
        <ul>
          <li>On a PC, just right-click on the zipped folder and choose "Extract All" from the options menu.</li>
          <li>On a Mac, just double-click the file, and Mac's unzipping utility does the rest.</li>
          <li>If you need to do this step on an iPhone or iPad, just tap on the zipped file, and it will unzip.</li>
          <li>
            If you need to do this on an Android phone, you will need to download the Files by Google or WinZip app
            (Android app) to your phone.
          </li>
        </ul>
        <h3>STEP 2: SAVE THE PRESETS</h3>
        <p>
          If you unzip the presets on your computer, you will need to save the preset files to your favorite cloud
          storage option so that you can access them on your mobile phone. You will also need to be able to allow access
          to those files on your phone through that same cloud storage app.
        </p>
        <p>
          If you unzip the preset download on your phone, you can skip this step. Ensure you know where the unzipped
          files are located on your phone or device.
        </p>
        <p>
          <b>THE DNG FILES are the only files you will need for the Lightroom Mobile app.</b> Ignore the xmp and
          lrtemplate files.
        </p>
        <img src={IMAGE.a2_1} alt="article" />
        <h3>STEP 3: INSTALL AND OPEN THE LIGHTROOM MOBILE CC APP</h3>
        <p>
          This step is straightforward. Just open your free Lightroom app. Once it's open, you can add the DNG/Preset
          files (next step).
        </p>
        <p>If you don't already have this app downloaded and installed, you can find the app here:</p>
        <ul>
          <li>DOWNLOAD Lightroom Mobile in the Apple App Store.</li>
          <li>DOWNLOAD Lightroom Mobile in the Google Play Store.</li>
        </ul>
        <h3>STEP 4: ADD THE DNG/PRESET FILES TO LIGHTROOM MOBILE</h3>
        <p>
          To add a preset to Lightroom Mobile, select a collection in Lightroom where you will import the preset files.
          Next, click on the (...) settings icon in the app's top-right corner.
        </p>
        <p>
          In the options that open at the bottom of the screen, choose the <b>Add Photos</b> option.
        </p>
        <p>
          When the options menu opens, choose the location you will be pulling the <b>DNG/Preset files</b> from—this is
          the location where you saved the DNG files after they were unzipped.
        </p>
        <ul>
          <li>
            If you have saved them to your phone, choose <b>"From Camera Roll."</b>{' '}
          </li>
          <li>
            If you have saved them to a cloud storage option, choose <b>"From Files."</b>{' '}
          </li>
        </ul>
        <img src={IMAGE.a2_2} alt="article" />
        <p>
          Select the DNG/Preset files you would like to import. This step may be a little different depending on your
          mobile phone. However, most phones offer a way to select multiple files/images.
        </p>
        <p>
          <b>NOTE: Preset files are DNG files.</b>{' '}
          <i>
            When you initially open the image, you may get an error that says, “Cannot Load File.” This is normal. Press
            the checkmark in the corner anyway.
          </i>
        </p>
        <h3>STEP 5: CREATE LIGHTROOM PRESETS FROM THE DNG FILES</h3>
        <p>
          Click on one of the newly imported files, then click on the three dots inside a circle in the upper right-hand
          corner of the screen. From there, choose “Create Preset” from the menu that appears.
        </p>
        <p>
          Give your preset a name. We recommend typing the same name written on the front of the preset image (to keep
          things simple).
        </p>
        <p>
          It would be best if you also put the preset in a Preset Group. By default, it will be in the User Presets
          group. Creating a new group with your new collection’s name will keep the presets from each collection
          together.
        </p>
        <p>
          <b>You will need to follow the instructions in this step for EACH DNG/preset file.</b>
        </p>
        <img src={IMAGE.a2_3} alt="article" />
        <h3>USING MOBILE PRESETS</h3>
        <p>Now for the FUN part!</p>
        <p>
          Select an image in the Lightroom Mobile App that you would like to edit. Swipe left on the bottom options
          until you see "Presets" and tap on that option. It will open with your most recently used preset collection.
          By tapping on the collection name, you can choose the new collection you just installed.
        </p>
        <p>
          Click through the presets until you find the one you like. When you are finished adding your preset, click the
          checkmark in the lower right corner.
        </p>
        <img src={IMAGE.a2_4} alt="article" />
        <p>
          <b>NOTE</b>: Our presets will not change your exposure or white balance since those settings can vary with
          each image. We encourage you to make those adjustments as necessary before or after choosing your preset.
        </p>
        <img src={IMAGE.a2_5} alt="article" />
        <p>Enjoy! Editing photos and getting beautiful results are so inspiring.</p>
      </>
    ),
  },
  {
    id: '67ebf9fa-1ac6-4fd1-94c7-b3fcfacda8fa',
    name: 'HOW TO SYNC LIGHTROOM PRESETS TO MOBILE',
    desc: 'The day has finally come, and we have been waiting for this feature FOREVER!  So how could we not post about the ability to use Pretty Presets for Lightroom in Lightroom CC for mobile devices!',
    image: IMAGE.main3,
    content: (
      <>
        <h3>PRESETS FOR LIGHTROOM MOBILE? CAN IT BE TRUE?</h3>
        <p>
          <b>The day has finally come, and we have been waiting for this feature FOREVER!</b> So how could we not post
          about the ability to use Pretty Presets for Lightroom in Lightroom CC for mobile devices!!
        </p>
        <p>
          Not only are our Presets now available for use in the Lightroom Mobile App, but you can also sync them with
          Lightroom CC, which makes getting those presets into your Lightroom Mobile app EASY!
        </p>
        <ul>
          <li>First, I will show you how to sync our Lightroom Presets with Lightroom Mobile. </li>
          <li>Next, I will demonstrate how to use our presets in the mobile app. </li>
          <li>Finally, I will show you a helpful trick for managing your presets you will not want to miss.</li>
        </ul>
        <p>
          <b>In fact, you will not want to miss ANY of the information in this post - IT IS THAT GOOD!</b>
        </p>
        <h3>HOW TO SYNC LIGHTROOM PRESETS TO MOBILE</h3>
        <p>
          You won't be able to sync your presets directly from Lightroom Classic CC to the Lightroom CC Mobile App.{' '}
          <b>You will need to use the Lightroom CC Desktop App as a middle man of sorts to get them there.</b> If you
          are a Creative Cloud subscriber, you DO have access to download this app.
        </p>
        <p>
          If you have previously installed your presets in the Lightroom CC Desktop Application, you are one step ahead.
          When you open up the Desktop App after installing or updating both the mobile and desktop Lightroom apps with
          the current version, your presets should now be automatically synced to the Lightroom CC Mobile App.
        </p>
        <img src={IMAGE.a3_1} alt="article" />
        <p>
          If you have not previously added presets or want to add some additional presets, follow the steps outlined
          below.
        </p>
        <p>
          1. You will need to download and install the Lightroom CC Desktop App if you don't have it, and make sure you
          have the most current version (at least the June 2018 update). **NOTE: This is different from Lightroom
          Classic CC.
        </p>
        <p>2. Launch Lightroom CC desktop version. </p>
        <p>
          3. <b>Go to File&gt;Import Profiles and Presets</b> and navigate to where your presets are located/saved on
          your computer.
        </p>
        <p>
          4. To finish this process, select the folders of presets you would like to add to your Lightroom mobile
          applications and then click Import. These presets will now be available in Lightroom CC and your Lightroom CC
          mobile app.{' '}
        </p>
        <img src={IMAGE.a3_2} alt="article" />
        <h3>HOW TO USE PRESETS IN THE LIGHTROOM MOBILE APP</h3>
        <p>
          Now that your presets are correctly installed, I will show you how to use them in the Lightroom Mobile App.
        </p>
        <p>1. Open your Mobile App and choose a photo you would like to edit.</p>
        <p>
          2. Go to the Presets section. You will find it along the bottom of the screen, but you may have to scroll all
          the way to the right to find this option.
        </p>
        <p>
          3. Once you click on the Presets section, it will open to a random preset collection. I have noticed the
          presets will not usually be in the same order they appear in your Lightroom Classic CC - but they will ALL be
          there.
        </p>
        <p>
          The collection name will be listed at the top of the preset menu with the individual presets listed below, and
          you can scroll through all your available presets. To apply a preset, simply tap the preset name.
        </p>
        <img src={IMAGE.a3_3} alt="article" />
        <p>
          4. To change the collection of presets, tap on the collection name at the top of the preset options. Doing
          that will bring up a list of all the preset collections. Select the preset collection you want, and you will
          be taken to a list of the presets in that collection.
        </p>
        <p>
          I love that they include previews of each preset, even if they are small! A little trick to make them larger
          is to turn your mobile device horizontal, and the previews will show slightly larger.
        </p>
        <img src={IMAGE.a3_4} alt="article" />
        <p>
          5. If you don't like the effect of the preset you clicked on, you can tap the undo arrow at the top of the
          screen or choose a new preset to be applied instead of your previous choice.
        </p>
        <p>
          6. To close the presets menu, <b>click the "X."</b>
        </p>
        <p>
          7. Once you have added a preset and closed the preset menu, you can still make additional adjustments to color
          or exposure using all the other options at the bottom of the screen!
        </p>
        <h3>MANAGING PRESETS IN LIGHTROOM CC DESKTOP AND MOBILE</h3>
        <p>If you are anything like me, you probably have MANY presets, and this last tip will be super helpful!</p>
        <p>
          With the new update, you can now manage which presets you see in your presets menu! I LOVE that I can now turn
          off all the default presets that come with Lightroom since I prefer using my Presets from Pretty Presets -
          they are SO MUCH BETTER!
        </p>
        <p>
          This option is available in every current version of Lightroom CC (however, for this tutorial, we will
          concentrate on the steps for the CC Desktop and Mobile versions).
        </p>
        <h3>TO MANAGE YOUR PRESETS IN THE LIGHTROOM CC DESKTOP VERSION:</h3>
        <p>1. Open the Presets panel.</p>
        <p>
          2. Click on the three dots (. . .) at the top of the Presets menu and select the "Manage Presets" option. This
          will open a list of all the presets you installed with a small check box next to each one.{' '}
        </p>
        <p>3. Uncheck any preset options you DON'T want to see in your Presets menu. </p>
        <p>4. When you finish, click "Back."</p>
        <img src={IMAGE.a3_5} alt="article" />
        <h3>TO MANAGE YOUR PRESETS IN THE LIGHTROOM CC MOBILE VERSION: </h3>
        <p>1. With a photo open, click on the Preset menu at the bottom of the app.</p>
        <p>2. When the Preset menu is open, click on the three dots (. . .) on the top of the screen.</p>
        <p>
          3. Choose the <b>"Manage Presets"</b> option that will open at the bottom of the screen.
        </p>
        <p>
          4. Click on the dot next to any preset collection you would like to turn off. The dot will be on the left.
        </p>
        <p>5. Click "Done" to save your selections.</p>
        <p>
          <b>
            Using your Pretty Presets for Lightroom on your iPhone or Android device is something we have wanted to
            offer our customers for a very long time, and we are so happy that we can now do that.
          </b>
        </p>
        <p>
          Follow the simple steps outlined above to sync, use and organize your Pretty Presets whenever you choose to
          edit in Lightroom Mobile!
        </p>
      </>
    ),
  },
  {
    id: 'aa7054bb-e997-4426-a15a-3700b409c7d0',
    name: 'GUIDE TO FORCED PERSPECTIVE PHOTOGRAPHY',
    desc: 'Have you ever heard of Forced Perspective?  It`s a fascinating technique that allows you to create an optical illusion resulting in some amusing and surreal images.',
    image: IMAGE.main4,
    content: (
      <>
        <p>
          Have you ever heard of Forced Perspective? It's a fascinating technique that allows you to create an optical
          illusion resulting in some amusing and surreal images.
        </p>
        <p>
          Not only is it great fun, but it can also help you practice and solidify some basic photographic skills like
          depth of field and composition.
        </p>
        <p>
          If this sounds interesting, keep reading. In this article, I will explain everything you need to know about
          forced perspective and some cool tricks to help you achieve fun and unique images.
        </p>
        <p>Let’s get started:</p>
        <h3>WHAT IS PERSPECTIVE?</h3>
        <p>
          Think about when you are looking at a completely straight road in front of you. As the road gets further away,
          it LOOKS like the edges are converging, even though the edges are, IN ACTUALITY, running entirely and
          continuously parallel to each other.
        </p>
        <p>
          This is because when you look at something far away, it will look smaller than it does when you are closer to
          it. It's a great example of how dramatically perception can change based on our vantage point.
        </p>
        <img src={IMAGE.a4_1} alt="article" />
        <p>
          We are so used to seeing the world in this way that we often take it for granted. As it turns out, this
          concept wasn't explained until c. 300 BC when Euclid presented his idea of how an object’s apparent size,
          shape, and position can change based on an individual's point of view.
        </p>
        <p>
          Even more surprising is how this concept wasn't used much in the Arts for MANY MORE centuries. In fact,
          establishing a vanishing point to more accurately represent a three-dimensional scene on a flat surface wasn’t
          very common until the Renaissance period. This technique, called perspective, is attributed to Filippo
          Brunelleschi.
        </p>
        <p>
          In photography, most things tend to be shot "IN PERSPECTIVE" so that the image represents what we see in a
          generally accurate way. However, it DOES NOT HAVE TO BE LIKE THIS - which is when Forced Perspective comes
          into play!
        </p>
        <h3>WHAT IS FORCED PERSPECTIVE?</h3>
        <p>
          <b>
            Forced perspective is an optical illusion that tricks the eye into thinking something is bigger or smaller
            than it actually is. It can also be used to change the APPARENT position of a subject or an object.
          </b>
        </p>
        <p>
          This technique is widely used in photography and film. Here are some photographers and films that utilize
          forced perspective that you can review for inspiration.
        </p>
        <p>
          <b>Photographers who use forced perspective:</b>
        </p>
        <ul>
          <li>Dimpy Bhalotia</li>
          <li>Hugo Suissas</li>
          <li>Rich McCor</li>
          <li>Pejac</li>
        </ul>
        <p>
          <b>Films that use forced perspective:</b>
        </p>
        <ul>
          <li>Citizen Kane</li>
          <li>Casablanca</li>
          <li>Eternal Sunshine of a Spotless Mind</li>
          <li>Lord of the Rings</li>
          <li>Elf</li>
        </ul>
        <h3>TIPS FOR AMAZING FORCED PERSPECTIVE PHOTOS</h3>
        <p>
          Taking a photo using forced perspective is not difficult, but there are several tips and tricks that can help
          you achieve better results:
        </p>
        <h4>1. EXPERIMENT WITH PLACEMENT</h4>
        <p>
          If you’ve ever taken a group selfie, you’ve probably noticed that the person holding the phone always looks
          larger than the people behind them.
        </p>
        <p>
          This is normal, especially using a wide-angle lens. Generally, whatever is closer to the camera will look
          bigger than objects further away.
        </p>
        <p>
          As you are experimenting with forced perspective, try moving the subject or subjects in your photo to
          different positions to change their APPARENT size in your frame.
        </p>
        <p>
          One of the most classic examples of this type of forced perspective photo is when a person looks like they are
          holding a famous landmark in their hands. This is accomplished by having the person standing relatively close
          to the camera and the landmark MUCH further away. The shot is then taken at an angle where the landmark LOOKS
          like it is resting in the subject's hands. When done correctly, the person will look like a giant, and the
          landmark looks like a souvenir reproduction.
        </p>
        <p>You can use many creative ideas to change the apparent proportion of things, so let your imagination fly.</p>
        <h4>2. USING FOCUS</h4>
        <p>
          If you are familiar with how cameras work, you probably know that a focal plane runs parallel to the sensor
          plane. This means that things on the same plane will have the same sharpness.
        </p>
        <p>
          If you want to create a forced perspective image in which two objects look closer together than they really
          are, you need to ensure that both things are in focus. Otherwise, viewers will quickly recognize the illusion.
        </p>
        <p>
          To do this, you need to use a deep depth of field which means that you should use a "wide-angle lens" and a
          "wide aperture." Try to keep as much distance as possible between you and the subjects. If you don't know how
          to keep both elements in focus, try using a depth-of-field calculator or use hyperfocal distance.
        </p>
        <p>
          On the other hand, if you want to make it look like there is MORE DISTANCE between two objects than there is,
          use a shallow depth of field. You can also use this technique to make things appear larger, which is why it's
          often used in miniature photography.
        </p>
        <h4>3. USE LIGHT TO YOUR ADVANTAGE</h4>
        <p>
          Utilizing light in particular ways is one of the oldest techniques used in forced perspective. For example,
          when you want to make something appear as if it’s further away from the camera than the other elements in your
          photo, you can make it darker than the other objects in your image.
        </p>
        <h4>4. COMPOSITION IS KEY</h4>
        <p>
          I know that composition is essential in all types of photography. How you arrange the elements within your
          frame can make or break an image.
        </p>
        <p>
          However, in the case of forced perspective photography, the composition is EVEN MORE IMPORTANT because
          everything needs to line up in a way that MAKES THE ILLUSION LOOK REAL.
        </p>
        <p>
          Make sure you have enough space to create your composition for the best results. Working outside can be
          helpful in this respect, depending on your subject and the scene you are trying to create.
        </p>
        <img src={IMAGE.a4_2} alt="article" />
        <h4>5. GET AN EXTRA PAIR OF EYES (AND HANDS)</h4>
        <p>
          Since forced perspective photography is all about the camera's point of view, it can be pretty tricky and
          time-consuming to adjust the composition in your frame if you have to keep moving back and forth from behind
          the camera to make changes.
        </p>
        <p>This is when having an assistant can be very helpful.</p>
        <p>
          If you’re shooting a self-portrait, have your assistant look through the viewfinder to help you get into
          position and, of course, press the shutter for you.
        </p>
        <p>
          If YOU are behind the camera, you can direct your assistant to move all the elements until they are in the
          perfect position for your photo.
        </p>
        <p>
          This may sound like generic advice, but in forced perspective photography, the changes you make can
          dramatically affect your outcome. So you have to be VERY PRECISE, which is much easier to do with the help of
          an assistant.
        </p>
        <h4>6. CHANGE THE VANTAGE POINT OR ANGLE OF VIEW</h4>
        <p>
          Another way to experiment with forced perspective is by changing your point of view or shooting from an
          unusual camera angle.
        </p>
        <p>
          You’ve probably seen images where a person looks like they are holding off an edge with their fingertips. This
          illusion is created by having the subject lying on the floor and then rotating the final image from horizontal
          to vertical or vice versa.
        </p>
        <p>
          This is just one example. There are all sorts of ways to use to change a subject's apparent size, proportion,
          shape, or position using different angles.
        </p>
        <img src={IMAGE.a4_3} alt="article" />
        <h4>7. CREATE FORCED PERSPECTIVE IN POST-PRODUCTION</h4>
        <p>
          While many forced perspective effects are done in-camera, you can also create these illusions using editing
          software like Photoshop.
        </p>
        <p>
          One of the easiest ways to alter perspective is by flipping or mirroring an image. If there are reflections on
          the photo, it can get even more interesting. This will challenge how a typical viewer will look at your image
          and make them want to spend more time contemplating what it is that looks off.
        </p>
        <p>
          Try experimenting with the Perspective Warp Tool in Photoshop. It is typically used to correct distortion in
          architectural photography. However, it is also a great way to change the perspective and proportions of any
          photograph.
        </p>
        <p>
          Finally, you can try creating a composite photo which usually requires a LOT of skill and editing time but
          also offers the most flexibility.
        </p>
        <h4>8. CREATIVITY</h4>
        <p>Your creativity is the best tool you have at your disposal to create amazing forced perspective images.</p>
        <p>
          Since the goal of forced perspective is to create an alternative way of looking at things, the sky is the
          limit here. There is no need to follow the rules of "reality," so get creative and have fun!
        </p>
        <h4>FINAL THOUGHTS</h4>
        <p>
          Forced perspective photography is a fun way to practice your photographic skills using light, focus,
          proportions, etc. Yet, it can also be considered a style of photography you can use to develop more complex
          projects.
        </p>
        <p>
          Whether you use forced perspective for a comic result or a surreal concept, it's a great way to express
          creativity. Have fun!
        </p>
      </>
    ),
  },
  {
    id: '7d273d28-1660-4783-8b5e-d572032613ab',
    name: 'HOW TO ADJUST MASKS USING THE AMOUNT SLIDER IN LIGHTROOM',
    desc: 'With the introduction of Lightroom 11, Adobe has introduced several features that have been long-awaited by Lightroom users.',
    image: IMAGE.main5,
    content: (
      <>
        <p>
          With the introduction of Lightroom 11, Adobe has introduced several features that have been long-awaited by
          Lightroom users.{' '}
        </p>
        <p>
          One of these features is the ability to control the "amount" or opacity of a mask using the newly included
          Amount Slider.
        </p>
        <h4>WHAT IS THE MASKING AMOUNT SLIDER?</h4>
        <p>
          The Amount Slider that is now available with the masking tools in Lightroom gives you the ability to adjust
          the amount of a mask you have applied or a mask preset you have used.{' '}
        </p>
        <p>
          It saves time by allowing users to adjust the amount of a mask with one straightforward slider rather than
          having to change multiple sliders you may have used to create the mask.
        </p>
        <h4>WHERE TO FIND THE MASKING AMOUNT SLIDER?</h4>
        <p>
          The Masking Amount slider is located at the top of the "Effect" portion of the masking controls. The Amount
          slider will appear as soon as you move some sliders or choose a brush preset (Lightroom Classic). It will
          remain grayed out until you apply the changes to your image.
        </p>
        <img src={IMAGE.a5_1} alt="article" />
        <h4>HOW TO ADJUST THE MASKING AMOUNT SLIDER?</h4>
        <p>
          Once changes have been made to your image, you will then have the ability to adjust the Amount slider to add
          more or less of the effect to your image:
        </p>
        <ul>
          <li>
            <b>To add MORE of the effect, drag the slider to the right (numbers greater than 100).</b>
          </li>
          <li>
            <b>To add LESS of the effect, drag the slider to the left (numbers less than 100).</b>
          </li>
        </ul>
        <p>It's as simple as that!</p>
        <img src={IMAGE.a5_2} alt="article" />
        <h4>WHERE ELSE CAN YOU FIND THE AMOUNT SLIDER?</h4>
        <p>
          Lightroom has also added the Amount Slider to the Presets Panel, so you will now see an Amount Slider for your
          presets in the Develop Module in Lightroom Classic and anywhere you add presets in Lightroom and Lightroom
          Mobile.
        </p>
        <p>
          However, the Masking Amount Slider is only available in Lightroom Classic and Adobe Camera RAW (ACR). This is
          excellent news if you use Photoshop's ACR to make your batch changes.
        </p>
        <img src={IMAGE.a5_3} alt="article" />
        <p>
          The Amount Slider is a fantastic addition to Lightroom! You can save SO MUCH TIME by adjusting your brushes
          using a single slider, so make sure you learn how to use it!
        </p>
        <p>
          And make sure to keep a close eye on all the new Lightroom updates. Many new features are making their way to
          Lightroom. Of course, we will keep you updated here on our blog!
        </p>
      </>
    ),
  },
  {
    id: '42bb347f-f3ad-4a76-b0f5-5947c6327295',
    name: 'TOP 5 TIPS FOR BEAUTIFUL FALL PHOTOGRAPHY',
    desc: 'Without a doubt, my favorite time of year to shoot is in the early Fall.  It`s the perfect time for family sessions and mini-sessions, too.  I love capturing all the beautiful colors in this short window mother nature gives us.',
    image: IMAGE.main6,
    content: (
      <>
        <p>
          Without a doubt, my favorite time of year to shoot is in the early Fall. It's the perfect time for family
          sessions and mini-sessions, too. I love capturing all the beautiful colors in this short window mother nature
          gives us.
        </p>
        <p>
          Today, I'm excited to share some helpful Fall photography tips for making this a successful season for your
          photography business.
        </p>
        <p>
          <b>Here are a few ways to make the most of your Autumn photo sessions:</b>
        </p>
        <h3>1. TAKE ADVANTAGE OF THE FALL COLORS</h3>
        <p>
          Fall is the perfect time of year to shoot outdoors. When the leaves are in full color, just before they fall
          off the trees, they offer a gorgeous backdrop for any type of photography.
        </p>
        <p>
          To boost the contrast and Fall colors, even more, try attaching a polarizing filter to your lens. Polarizing
          filters will subtly increase the contrast of your image in-camera and save you from having to add contrast in
          post-processing.
        </p>
        <h3>2. WEAR THE RIGHT CLOTHES</h3>
        <p>
          If you are photographing individual or family portraits, suggest that your clients wear solid colors. If they
          insist on patterned clothing, make sure the pattern is very basic. There is already so much color in nature
          during Autumn that it can make heavily patterned clothing appear extremely busy against the backdrop of bright
          Fall colors.
        </p>
        <p>
          Also, pay attention to the color of the leaves versus the color of your subject's clothes. Try to get your
          client to dress in colors that are complementary to red, yellow, and orange. These include shades of blue and
          green; of course, the classic black, white, and gray combo is always a winner!
        </p>
        <img src={IMAGE.a6_1} alt="article" />
        <h3>3. LIGHTING</h3>
        <p>
          In the fall, you may deal with sunny or completely cloudy days. Here are some tips for shooting in both
          situations!
        </p>
        <p>
          For sunny days, try shooting when the light is soft – either in the morning or early evening. Shooting at
          Golden hour is ideal. This time of day will decrease the harsh shadows on your subject and illuminate the
          leaves on the trees, creating a stained-glass effect with all the colors.
        </p>
        <p>If the sky is cloudy, you may find that the scenery looks a bit gloomy. Don’t fret, though!</p>
        <p>
          A tiny pop of flash will increase the contrast and saturation of the colors of the leaves and illuminate your
          subject. Don’t overdo the flash – a little bit goes a long way. The goal is to avoid creating shadows with
          your flash, so you will, most likely, need to set your flash close to its lowest setting or use a flash
          diffuser. Here is an excellent cheat sheet for lighting.
        </p>
        <h3>4. CAMERA SETTINGS</h3>
        <p>
          Fall is the perfect time to take advantage of shooting with a wide aperture to create some dreamy, blurry
          background bokeh that will make viewers swoon! A wide aperture (for example, between f/2.8 and f/4.5) will
          blur the leaves while maintaining perfect focus on your subject.
        </p>
        <p>
          On the other hand, you can try experimenting with a narrow aperture (f/8 and higher) to get more of the leaves
          in focus. This can result in some interesting shots, but you MAY find the increased focus on the leaves makes
          the various colors too busy and takes the focus away from your subject.
        </p>
        <h3>5. POST-PROCESSING</h3>
        <p>
          Depending on where you live (and many other factors, including weather, lighting, etc.), you may find your
          images need additional post-processing to achieve a beautiful Fall look. Or you may just want to enhance the
          warm red & orange leaves that can only be found for a short time every year.
        </p>
        <p>
          These beautiful presets & brushes will quickly transform and infuse your images with warm, vibrant colors,
          golden hues, and rich color enhancements that are perfect for a Fall look. Often these enhancements are
          completed in one click, but as with all presets, sometimes you may need to make some minor adjustments to
          exposure and white balance settings in Lightroom, depending on your camera and shooting conditions.
        </p>
        <p>
          <b>
            As you can see from all the BEFORE and AFTER photos in this blog post, the Warm & Organic Millennium Preset
            Collection does an incredible job of enhancing and unifying the various tones and colors that occur
            naturally during the Fall.
          </b>
        </p>
        <img src={IMAGE.a6_2} alt="article" />
        <p>
          And if you use Photoshop and want to add something extra special to your Fall images, check out the Falling
          Leaves Overlays and Photoshop Action Collection, which I use to add gorgeous leaf overlays to many of my Fall
          images. This unique collection includes over 100 leaf overlays authentically created from "real leaves,"
          several beautiful Fall toning actions, and more.
        </p>
      </>
    ),
  },
  {
    id: '15896814-ed26-4806-a60d-ac02f7c6e0a9',
    name: 'HOW TO FIND MISSING LIGHTROOM PANELS',
    desc: 'Lightroom side panels are hidden when you right-click over one of the panels and click on the panel name (which has a checkmark next to it).  This often happens accidentally when you (or maybe your children or pets) click the keyboard or mouse while you have Lightroom open.',
    image: IMAGE.main7,
    content: (
      <>
        <p>
          Have you ever been using Lightroom, editing your way through a collection of images, only to suddenly realize
          your "Detail" panel is missing, or your "Presets" panel has disappeared? If this hasn't happened to you yet,
          it probably will happen eventually, so I want to share this simple fix to prevent you from experiencing a
          freak-out like I did. ;-)
        </p>
        <h3>HOW LIGHTROOM PANELS DISAPPEAR</h3>
        <p>
          Lightroom side panels are hidden when you right-click over one of the panels and click on the panel name
          (which has a checkmark next to it). This often happens accidentally when you (or maybe your children or pets)
          click the keyboard or mouse while you have Lightroom open.
        </p>
        <p>
          It happened to me when I was rushing through an edit and wasn't paying close attention to what I was clicking
          on. So if this happens to you too, don't feel bad - you are not alone!
        </p>
        <p>
          Panels can go missing in both the Library and the Develop modules, but for me, it happens way more often in
          the Develop module, likely because I have this module open a little more often.
        </p>
        <h3>HOW TO BRING LIGHTROOM PANELS BACK</h3>
        <p>
          The good news is that your Lightroom panels aren't gone forever; they are merely hidden from view. There are
          two ways to get your panels to show again, one of which is quicker than the other.
        </p>
        <h4>METHOD #1</h4>
        <p>
          The first and quickest method to find your missing Lightroom panel is to right-click on one of the panels that
          are STILL showing—the panel you right-click on should be next to or at least on the same side of Lightroom as
          the panel that is missing.
        </p>
        <p>
          If that panel is on the right side of the Develop Module, when you right-click, choose the "Customize Develop
          Panel" option. This will open up a second panel that will allow you to decide which panels you want to show.
          If there is a check by the panel name, it will show in the Develop Module.
        </p>
        <img src={IMAGE.a7_1} alt="article" />
        <p>
          Just follow these steps if a panel is missing from any other module or even on the left side of the Develop
          module. Right-click on a panel and a small drop-down menu with a list of panels will appear. Note that the
          missing panels will not have a checkmark next to them, so simply click to add a checkmark to the boxes next to
          the missing panels to bring them back.
        </p>
        <img src={IMAGE.a7_2} alt="article" />
        <h4>METHOD #2</h4>
        <p>
          The second method to bring back your missing panel(s) is via the main menu at the top of Lightroom. Click on
          Window (shown below), then Panels, and lastly, click on the missing panel. When you do this, your missing
          panel will instantly appear!
        </p>
        <img src={IMAGE.a7_3} alt="article" />
        <p>
          <b>Both methods are quick and easy, so choose the one that works best for you!</b>
        </p>
      </>
    ),
  },
  {
    id: '83f380e3-8f39-4a74-af68-8e1e76c266ad',
    name: 'RAW VS JPEG (WHAT`S THE DIFFERENCE?)',
    desc: 'What`s the difference between shooting RAW and shooting JPEG?  This is a very common question that many photographers have, especially new ones.',
    image: IMAGE.main8,
    content: (
      <>
        <p>
          What's the difference between shooting RAW and shooting JPEG? This is a very common question that many
          photographers have, especially new ones.
        </p>
        <p>
          Many think the ONLY benefit of shooting RAW is the ability to "rescue" an image. This, however, is just the
          beginning. There are several other fantastic benefits to shooting RAW that are less known, and we will explain
          all of these below:
        </p>
        <h3>WHAT IS THE DIFFERENCE BETWEEN RAW AND JPEG FILES</h3>
        <p>To understand this concept, it is helpful to understand both RAW and JPEG files themselves.</p>
        <ul>
          <li>
            A RAW file is a file containing all of the information your camera's sensor recorded during exposure. No
            compression or processing is applied to a RAW file by the camera.
          </li>
          <li>
            In contrast, a JPEG file is a file that your camera has compressed and does not contain all of the
            information your camera's sensor recorded during exposure. Instead, your camera has chosen certain
            information to discard.
          </li>
        </ul>
        <img src={IMAGE.a8_1} alt="article" />
        <p>
          Now, as many photographers may already know, the information contained in RAW files grants the ability to:
        </p>
        <ul>
          <li>Easily correct under or overexposed images without a drastic loss in quality.</li>
          <li>Adjust white balance,</li>
          <li>
            Achieve greater levels of detail and sharpness in post-processing than is feasible when using JPEG files.
          </li>
        </ul>
        <p>
          <b>
            As you view the comparison photos included in this post, the difference in the photos will be subtle. Look
            specifically for the increased contrast and saturation in the JPEG versions. You will also note changes as
            you compare the highlights and shadows of the images.
          </b>
        </p>
        <p>
          <b>Now, let's take a closer look at the differences between RAW and JPEG:</b>
        </p>
        <h4>1. EXPOSURE: RAW VS. JPEG</h4>
        <p>
          When using a JPEG file, information in the brightest and darkest areas of your image is lost, and in a case of
          under or overexposure, the detail in these areas is not recoverable.
        </p>
        <p>
          This is not the case with RAW files, and most exposure issues can be easily corrected. While it is always
          ideal to achieve the best image in camera, every photographer will take an under or overexposed image from
          time to time.
        </p>
        <img src={IMAGE.a8_2} alt="article" />
        <h4>2. WHITE BALANCE: RAW VS. JPEG</h4>
        <p>
          The white balance of RAW files is easily adjusted. This not only allows for the correction of an undesirable
          white balance but also allows for a multitude of creative options in post-processing. In contrast, a JPEG file
          has a set white balance and is difficult to adjust. This means less control over the colors seen in your
          image.
        </p>
        <h4>3. BRIGHTNESS: RAW VS. JPEG</h4>
        <p>
          Another benefit worth considering is the wide range of artistic freedom RAW files grants to the photographer.
          Consider this: A JPEG file records less than 300 levels of brightness. A RAW file, on the other hand, can
          record anywhere from 4000 to over a whopping 16,000 levels of brightness!
        </p>
        <p>
          The brightness levels recorded directly affect the adjustments you will or will not be able to make in
          post-processing. The multitude of brightness levels recorded in a RAW file grant creative control to the
          photographer, namely you or me!{' '}
        </p>
        <p>
          For instance, because my images are bold, colorful, and creatively edited, shooting in RAW is very important
          to my workflow.
        </p>
        <h4>4. CONTRAST: RAW VS. JPEG</h4>
        <p>
          RAW files come out of the camera with less contrast and saturation than the image you see on your camera
          viewscreen. When using RAW, your camera manufacturer assumes that you will be developing this photo with
          software, like Lightroom or Photoshop, leaving the image development to you.
        </p>
        <p>
          On the other hand, JPEG files come out with the same saturation and contrast as you saw on the back of your
          camera. This file type works great for those who like to print or display their shots straight from the camera
          without post-processing.
        </p>
        <h4>5. SHARPNESS: RAW VS. JPEG</h4>
        <p>
          Another notable feature of a JPEG file is the appearance of sharpness. While JPEG files APPEAR sharper than
          RAW files, this is not necessarily the case.
        </p>
        <p>
          The sharpness seen in a JPEG file is the result of your camera's processing system. The processing systems
          available for a computer are far more advanced than the system your camera is utilizing. The highest level of
          detail and sharpness will be achieved by processing RAW files using software such as Adobe Lightroom or
          Photoshop.
        </p>
        <img src={IMAGE.a8_3} alt="article" />
        <h4>6. DESTRUCTIVE VS. NON-DESTRUCTIVE EDITING</h4>
        <p>
          Finally, one of the most significant benefits of shooting in RAW format is the ability to edit
          non-destructively. When processing a RAW file, the original file is not directly affected. Essentially, the
          RAW file you are editing is a reference file, with the edit being a set of directions that will be applied
          when your image is exported.
        </p>
        <p>
          When processing a JPEG file, you will experience a loss of quality. This loss of quality occurs each time a
          JPEG file is opened, edited, or saved.
        </p>
        <h4>7. FILE SIZE: RAW VS. JPEG</h4>
        <p>
          As I previously mentioned, RAW files contain MUCH MORE camera sensor information than JPEG files, so
          naturally, RAW files are SIGNIFICANTLY LARGER than JPEG files. You will need to keep this in mind when
          considering your storage requirements.
        </p>
        <h3>LIGHTROOM PREVIEWS</h3>
        <p>
          When importing RAW files into Lightroom, you will initially see the JPEG preview that was captured by the
          camera. As Lightroom creates previews of the RAW files, the preview will change so that you see a preview of
          the RAW file. Many mistake this as Lightroom "editing" or "changing" their image. This isn't the case.
          Lightroom just takes a few minutes to generate those RAW file previews.
        </p>
        <h3>CONCLUSION</h3>
        <p>
          <b>
            Is shooting in the RAW format right for you? Only you can say what works best for you and your workflow!
          </b>
        </p>
        <p>
          But this information is well worth considering and, hopefully, answers a few of your questions concerning RAW
          and JPEG files.
        </p>
      </>
    ),
  },
  {
    id: '9028aa1f-bcdf-4efc-af0e-225cb25553dc',
    name: '3 WAYS TO RENAME YOUR FILES IN LIGHTROOM',
    desc: 'In most programs, there is more than one way to do something.  And that is definitely true for renaming your files in Lightroom.  Today I will share the three ways to rename your files in Lightroom and when you might want to use each method.',
    image: IMAGE.main9,
    content: (
      <>
        <p>
          In most programs, there is more than one way to do something. And that is definitely true for renaming your
          files in Lightroom. Today I will share the three ways to rename your files in Lightroom and when you might
          want to use each method.
        </p>
        <h4>METHOD 1. RENAMING ON IMPORT</h4>
        <p>
          To rename your photos when importing, go to the File Renaming Tab on the right-hand side of the Import dialog
          box.
        </p>
        <img src={IMAGE.a9_1} alt="article" />
        <p>
          I import all my photos via Lightroom, and I rename all my files on import to get rid of the letters my camera
          places at the beginning of each filename - DSC (Nikon) or IMG (Canon). These just bug me, and I don’t want to
          see them.
        </p>
        <p>
          <b>
            This is the type of renaming you want to do on import - a process you will want to do for EVERY photo you
            import.
          </b>
        </p>
        <p>
          <b>Follow these steps to create your own preset that renames your photos:</b>
        </p>
        <ul>
          <li>
            Check the "Rename Files" box in the File Renaming panel. From the Template drop-down menu, you can choose
            one of those options. If needed, new options will open up in the File Renaming Panel to help you. If you
            want to make a custom option, choose “Edit” at the bottom of the drop-down menu.
          </li>
          <li>
            If you want to make a custom option or preset, choose "Edit" at the bottom of the drop-down menu selected
            above. A new box will open up. Delete all the text in the white box. Then choose the options that will give
            you the result you are looking for. Each drop-down menu below the large white box has several options for
            you to choose from. When you find the one you like, press "Insert."
          </li>
          <li>
            To save these settings as a preset, go to the top drop-down menu and choose “Save Current Settings as New
            Preset.” Give your preset a name and click “Create.”
          </li>
        </ul>
        <img src={IMAGE.a9_2} alt="article" />
        <h4>METHOD 2. RENAMING FILES AFTER SELECTING FAVORITES</h4>
        <p>
          I like to use this type of renaming for client photos. I typically don’t love the long sequence of numbers
          that most of my files are left with after they have been taken. So once I have chosen my favorites, I select
          and rename them.
        </p>
        <p>
          To do this, you need to be in the Library module. Then, select the photos you want to rename and hit the "F2"
          key or choose Rename Photos from the Library drop-down menu. (go here for my other favorite Lightroom keyboard
          shortcuts
        </p>
        <p>
          This will open a dialog box that will give you several options for renaming your files. “Custom Name-Sequence”
          is the one that I choose most often. Using this method, I can add the client’s name (custom name) and start
          the numbering of these favorites at #1 (sequence).
        </p>
        <p>
          I choose this renaming method (over the next one I will tell you about) because I want the files that I give
          my client to have the exact same name on my hard drive. Otherwise, things can get very confusing, especially
          if you try to look up a photo to edit later on.
        </p>
        <h4>METHOD 3. RENAMING ON EXPORT</h4>
        <p>
          You can also rename on export from Lightroom. This is another way that I rename photos, but I DO NOT use it
          frequently for Client photos for the reason I just mentioned in the previous paragraph.{' '}
        </p>
        <p>
          I DO sometimes use this renaming technique on files I am printing (to differentiate them from other files) or
          posting to my blog or social media (to have keywords in my photo title).
        </p>
        <p>
          <b>To rename on export:</b>
        </p>
        <ul>
          <li>First, select the files that you want to export. </li>
          <li>
            Right-click and choose “Export” or press "CTRL + Shift + E" (PC users) or "CMD + Shift + E" (Mac users).{' '}
          </li>
          <li>Then scroll down the section that gives you the options for file renaming.</li>
          <li>Click the box next to “Rename to:” and choose the options you want as your photos are exporting. </li>
          <li>Once you click “Export,” they will also be renamed.</li>
        </ul>
        <img src={IMAGE.a9_3} alt="article" />
        <p>
          <b>From all of us at Pretty Presets, Happy Editing!!</b>
        </p>
      </>
    ),
  },
  {
    id: '488e7bd6-7ee2-4f6d-88b8-4e97ca58e843',
    name: 'HOW TO MERGE LIGHTROOM CATALOGS',
    desc: 'Do you have MORE THAN ONE Lightroom Catalog? You are not alone. This is a very common error that many new Lightroom users make!',
    image: IMAGE.main10,
    content: (
      <>
        <p>
          Do you have MORE THAN ONE Lightroom Catalog? You are not alone. This is a very common error that many new
          Lightroom users make!
        </p>
        <p>
          Over the years, I have helped many Lightroom users and have seen many of them create a brand new catalog EVERY
          TIME they import. One individual had over 200 separate Lightroom catalogs! Yikes!
        </p>
        <p>
          Most Lightroom users only need to have ONE catalog. Even if you are a photographer, I highly advise keeping
          just one catalog and using Lightroom Collections to separate your personal and professional images.
        </p>
        <p>
          <b>If you have already created multiple Lightroom catalogs, don't worry; there is a relatively simple fix!</b>
        </p>
        <p>
          You will need to merge your Lightroom catalogs into a single catalog. In my case, I had three Lightroom
          catalogs: one for my Mac mini desktop computer, an old one from a PC desktop that bit the dust two years ago,
          and my laptop catalog. I recently imported the Mac Mini and the PC catalogs into my laptop catalog, and I
          thought I would share the process with you.
        </p>
        <h3>HOW TO MERGE LIGHTROOM CATALOGS</h3>
        <p>
          To merge catalogs, you will essentially be "importing" pictures from one catalog to another catalog. When you
          do that, all the edits, keywords, ratings, flags, collections, and everything attached to every image in that
          catalog gets brought into the catalog you have open. So, you will be "merging" that particular Lightroom
          catalog with the one you are currently using.
        </p>
        <h4>1. OPEN MASTER CATALOG</h4>
        <p>
          Start by opening the catalog you want to have as your <b>"Master Catalog."</b>
        </p>
        <h4>2. IMPORT FROM ANOTHER CATALOG</h4>
        <p>Next, go to File in the top menu and click on "Import from Another Catalog" from the drop-down menu.</p>
        <img src={IMAGE.a10_1} alt="article" />
        <h4>3. NAVIGATE TO CATALOG YOU WANT TO MERGE</h4>
        <p>
          Find the catalog you want to merge with the <b> "Master Catalog"</b> you already have open and take note of
          its location.
        </p>
        <p>
          When you click on <b>"Import from Another Catalog"</b> your Mac Finder or Windows folders will open up. You
          must navigate to wherever that other catalog is that you want to merge.
        </p>
        <p>
          In the image below, you can see that I went to an external drive where I had copied the catalog from my
          desktop computer.
        </p>
        <h4>4. CLICK ON LRCAT FILE</h4>
        <p>
          Click to highlight the file that ends in .lrcat, and then click <b>"Choose."</b>
        </p>
        <img src={IMAGE.a10_2} alt="article" />
        <h4>5. CHOOSE IMPORT CATALOG SELECTIONS</h4>
        <p>
          Once you click <b>"Choose"</b> you will see a menu of options to select from, just like in the image below. Go
          through each option carefully, making sure you are happy with your choices.
        </p>
        <p>
          You can choose which folders you want to import from, if you want to move the photos or simply add them to the
          catalog, or if you're going to replace images that you may already have in the current catalog.
        </p>
        <p>
          <i>
            *** Please note that the items in the green boxes are NOT a choice recommendation, but rather highlighting
            areas that you need to make choices.
          </i>
        </p>
        <img src={IMAGE.a10_3} alt="article" />
        <h4>6. DELETE OLD CATALOG</h4>
        <p>
          Once you have all the images (that you want) imported, you can simply delete that old catalog and the support
          files that come with it. Or, you can store the old catalog away on a separate drive.
        </p>
        <p>
          After you have all your various catalogs imported, you may want to rename your master Lightroom catalog to
          something memorable, so you know for sure that it is the catalog you want to use going forward.
        </p>
        <h4>7. OPTIONAL CLEANUP</h4>
        <p>
          After I imported images from both catalogs (Mac desktop and PC desktop), I had a lot of housekeeping (file
          management) to tackle. I had to move folders from one drive to another, as well as find and eliminate
          duplicate photos.
        </p>
        <p>
          <b>Just make sure you do all this while you are INSIDE Lightroom.</b>
        </p>
      </>
    ),
  },
  {
    id: '0f4b61b6-b4ca-44c3-837c-380a92a04fb5',
    name: '6 FRESH PHOTOGRAPHY IDEAS TO INSPIRE YOUR CREATIVITY',
    desc: 'As photographers, we may often find ourselves in a bit of a slump.  The dreaded creative rut can rear its ugly head for all of us from time to time.  But the positive side to this is that GROWTH USUALLY OCCURS RIGHT AFTER!',
    image: IMAGE.main11,
    content: (
      <>
        <p>
          As photographers, we may often find ourselves in a bit of a slump. The dreaded creative rut can rear its ugly
          head for all of us from time to time. But the positive side to this is that GROWTH USUALLY OCCURS RIGHT AFTER!
        </p>
        <p>
          Whether you are a portrait, landscape, or commercial photographer, experimentation and getting out of your
          comfort zone can lead to rewarding results!
        </p>
        <p>
          Here are a few tips to help you turn lemons into lemonade when you need to flex your photographic creativity:
        </p>
        <h4>1. USE A DIFFERENT CAMERA OR LENS</h4>
        <p>One of the reasons we can get into a slump is when we use the same equipment for every single shoot.</p>
        <p>
          Whether you are putting down your DSLR to shoot with a point-and-shoot, or setting your fixed lens aside to
          shoot with a zoom, getting out of your comfort zone with equipment will force you to compose your shots in a
          new way.
        </p>
        <p>
          Of course, you can go back to your favorite gear once you are done with the exercise, but most likely, you
          will have a fresh outlook!
        </p>
        <img src={IMAGE.a11_1} alt="article" />
        <h4>2. BE CHILDLIKE</h4>
        <p>
          Kids are extremely creative because they have no inhibitions when they express themselves. They operate solely
          on the notion of creating what is pleasing to their eye, and they really don’t worry about what everyone else
          thinks.
        </p>
        <p>
          If, as adults, we could let go like we were kids again, we would probably feel much freer in our creative
          work.
        </p>
        <p>
          My 6-year-old stepson absolutely loves photography, and I am always amazed at the photos he captures with his
          tiny (and old) point-and-shoot! He gets down on the ground, up in trees, in the bushes - you name it, he is on
          it with his camera.
        </p>
        <img src={IMAGE.a11_2} alt="article" />
        <h4>3. COLLABORATE</h4>
        <p>
          Enlist the help of a fellow photographer and collaborate. As photographers, we all understand each other
          fairly well (most of the time, at least), and having a partner for collaborations can introduce you to new
          ideas, techniques, locations, and post-processing – the sky’s the limit, really!
        </p>
        <p>
          Your collaboration could be something as simple as an afternoon photo stroll, or something complex, like a
          Photoshop project.
        </p>
        <img src={IMAGE.a11_3} alt="article" />
        <h4>4. ASK A NON-PHOTOGRAPHER</h4>
        <p>
          With the idea of collaboration in mind, get a friend or family member, preferably not a photographer, to help
          you.
        </p>
        <p>
          For example, have your friend select two or three objects, and then photograph those objects in several
          different ways, switching up your angle, lighting, and even your post-processing.
        </p>
        <p>
          Having someone who is not a photographer help you out with an exercise like this can be a great experience,
          because they may notice things that you, yourself, may otherwise overlook.
        </p>
        <img src={IMAGE.a11_4} alt="article" />
        <h4>5. PHOTOGRAPH SOMEONE OR SOMETHING IMPORTANT</h4>
        <p>
          Maybe you have a family heirloom, your child’s favorite toy, or a gag gift from your best friend that you
          would like to photograph. Perhaps you would like to photograph your grandparents, your favorite aunt, or a
          location that is special to you.
        </p>
        <p>
          Any person, object, or location that stirs your emotions is a great subject for your photography because when
          your emotions are a part of your work, your images will shine, and take on a very special meaning to you.
        </p>
        <img src={IMAGE.a11_5} alt="article" />
        <h4>6 FINALLY, BE INSPIRED</h4>
        <p>
          We are very fortunate to live in an age where inspiring photographs are just a Google search away! Looking at
          the work of other photographers can be a huge help in getting you through your creative slump.
        </p>
        <p>
          While I am not a fan of comparing ourselves to other photographers or blatantly recreating their work, taking
          the time to try to emulate what you love about their work can open you up to new possibilities.
        </p>
        <p>
          When you look at a photograph that inspires you, try to identify what “it” is that grabs you – lighting,
          colors, black and white tones, angles, focus – and experiment with those same techniques.
        </p>
        <img src={IMAGE.a11_6} alt="article" />
      </>
    ),
  },
  {
    id: '5918f4d6-b430-4e08-ae48-81f71f909474',
    name: 'HOW TO CHOOSE A TRIPOD (THE BASICS)',
    desc: 'One of my favorite pieces of equipment is my tripod.  I have incredibly shaky hands, and if I`m shooting anything slower than 1/60th, I will end up with a blurry photo, no matter how hard I try to hold my camera steady!',
    image: IMAGE.main12,
    content: (
      <>
        <p>
          One of my favorite pieces of equipment is my tripod. I have incredibly shaky hands, and if I'm shooting
          anything slower than 1/60th, I will end up with a blurry photo, no matter how hard I try to hold my camera
          steady!
        </p>
        <p>
          I wanted to write a post about tripods for anyone who shares my problem, and in doing some research for this
          post, I concluded: there is a lot of (often very confusing) information out there about tripods.
        </p>
        <p>
          <b>
            So in this post, I'm going to condense all the information down to a few essential factors that will help
            you select a tripod.
          </b>
        </p>
        <h3>WHY USE A TRIPOD?</h3>
        <p>
          There are hundreds of reasons why a tripod can be extremely useful, but here are some examples of why and when
          to use a tripod:
        </p>
        <ul>
          <li>
            To prevent camera shake and blur in your photos – having a tripod will ensure that your images will be sharp
            and will give you the option to shoot at a low ISO to minimize the noise in your photos.
          </li>
          <li>
            As a support system for heavy camera/lens setups – if you are on a long shoot and have a heavy set-up, you
            may find it helpful to put your camera on a tripod. Again, this will prevent camera shake when your arms get
            tired from carrying your camera.
          </li>
          <li>
            If you want to take photos at night and/or long exposures, a tripod is a necessity to prevent blur from
            camera movement.
          </li>
          <li>
            When shooting macro photography, achieving tack-sharp focus is extremely important. Having your camera on a
            tripod will help you frame your shot and keep the focus exactly where you need it when you press the shutter
            release.
          </li>
        </ul>
        <img src={IMAGE.a12_1} alt="article" />
        <h3>HOW TO CHOOSE A TRIPOD</h3>
        <p>
          If you're shooting macro photos of flowers in your kitchen, you should be able to get by with a basic,
          inexpensive tripod. However, if you're a landscape photographer and often find yourself on location, you will
          need something more sturdy.
        </p>
        <p>
          The process of selecting and purchasing a tripod can get a bit overwhelming. Don’t worry, though. Just keep
          these four criteria in mind as you are making your selection:
        </p>
        <img src={IMAGE.a12_2} alt="article" />
        <h4>1. HEIGHT</h4>
        <p>
          Determine how high - and how low to the ground, you will need your tripod to reach to achieve the type of look
          you're going for.
        </p>
        <p>
          Also, consider your own height - if you are tall, you will need a tripod that extends high enough to
          comfortably use without having to hunch over. Many tripods also come with a center leg that will allow you to
          increase the height.
        </p>
        <h4>2. WEIGHT</h4>
        <p>
          If you're going to be doing a lot of walking with your tripod, you may want to consider a lightweight material
          such as carbon fiber or aluminum. Keep in mind, though, that carbon fiber tripods are the lightest but tend to
          be on the pricier side. Aluminum is much less expensive and only slightly heavier than carbon fiber.
        </p>
        <h4>3. STABILITY</h4>
        <p>
          Stability is an important factor depending on your camera's weight and the terrain on which you will be using
          your tripod. The locks on the tripod legs come in either a flip lock or a twist lock. I prefer the twist lock,
          as I have found that sometimes the flip locks do not lock in all the way, and my camera slowly sinks, making a
          long exposure blurry due to the movement.
        </p>
        <p>
          It is also important to consider the type of feet on a tripod. I do a lot of shooting outdoors, so the feet on
          my tripod are rubber with a metal spike to secure them to the ground.
        </p>
        <h4>4. MOBILITY</h4>
        <p>
          The ability to move your camera to various angles on your tripod is essential, especially in portrait
          photography. The tripod head is what allows you to move your camera around.
        </p>
        <p>Two of the most common types of heads are pan-tilt and ball.</p>
        <ul>
          <li>
            A pan-tilt head has handles that allow you to move your camera to either portrait or landscape
            (vertical/horizontal). This is the most common type of tripod head and is found on most lower-end tripods.
          </li>
          <li>
            The ball head allows a bit more flexibility and is generally more expensive. These heads usually do not have
            handles but do have a control to loosen the head as you move your camera into the desired position.
          </li>
        </ul>
      </>
    ),
  },
];

export default BLOG;
