import clsx from 'clsx';

import { useScroll } from 'hooks';
import { scrollToTop } from 'utils/pageUtils';
import { ReactComponent as ArrowIcon } from 'assets/images/arrowNav.svg';

import s from './s.module.scss';

const ToTop = () => {
  const { scrollY } = useScroll();

  return (
    <button type="button" className={clsx(s.scroll_to_top, scrollY > 800 && s.show)} onClick={scrollToTop}>
      <ArrowIcon />
    </button>
  );
};

export default ToTop;
