import { Button } from 'components';
import { ModalLayout } from 'components/_Modals';

import s from './s.module.scss';

const ConfirmModal = ({ isOpen, onClose, state, mode = 'confirm' }) => {
  const { question, text, confirmLabel, closeLabel, onConfirm } = state || {};

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
    handleClose();
  };

  return (
    <ModalLayout isOpen={isOpen} onClose={handleClose} size="sm">
      <div className={s.modal_wrapper}>
        {question ? <p className={s.question}>{question}</p> : null}
        {text ? <p className={s.text}>{text}</p> : null}
        <div className={s.actions}>
          <Button onClick={handleClose} label={closeLabel || 'Cancel'} round="block" />
          {mode === 'confirm' ? (
            <Button label={confirmLabel || 'Confirm'} onClick={handleConfirm} round="block" theme="whiteOutline" />
          ) : null}
        </div>
      </div>
    </ModalLayout>
  );
};

export default ConfirmModal;
