import { IntroPage } from 'components';
import { CONTACTS } from 'constants/enums';
import IMAGE from 'assets/images';

const RefundPolicy = () => {
  return (
    <div className="smooth_enter_an">
      <IntroPage title="Refund Policy" image={IMAGE.kellySikkema} />
      <div className="main_container page_content mb60">
        <h3>OVERVIEW</h3>
        <p>Your statutory rights are not affected.</p>
        <p>
          Our products are digital downloads. Once you purchase and download a product, you have gained access so it
          cannot be returned. However, customers may request a refund under the following circumstances.
        </p>
        <p>
          If, within 10 days of purchase, you have any issues at all with your product, please reply to your order
          confirmation email and describe your issue. We will endeavor to help with your issue(s) and may require
          further information and co-operation from you during the troubleshooting phase. If if the product you have
          purchased still does not work as advertised/described after we have assisted you, and provided you initially
          contacted us about any issues within 10 days of the order, then we will refund you.
        </p>
        <p>
          We can issue a refund without question if you have not yet downloaded your purchased product (this shows at
          our end) or if you have accidentally purchased the same product more than once.
        </p>
        <h6>Exceptions</h6>
        <p>
          We cannot be held responsible if you failed to check that the product is compatible with your software or if
          you failed to check that the product is best used with English versions of the software.
        </p>
        <p>
          We cannot be held responsible if you do not know how to use the product you have purchased. or if you have
          purchased the wrong purchase the wrong product by mistake.
        </p>
        <p>
          We cannot be held responsible if you update your software and can no longer use the product, or if you use the
          wrong software for your product.
        </p>
        <p>
          We cannot be held responsible if you do not know the basics of how to use the software (for example, Photoshop
          or Lightroom) or if you did not read the product description properly.
        </p>
        <p>
          <b className="col_blue_wood">{CONTACTS.APP_NAME}</b> does not accept liability for future updates to the
          software needed to run any <b className="col_blue_wood">{CONTACTS.APP_NAME}</b> products. Your purchased
          product is guaranteed to work with Adobe Creative Cloud at the time of purchase. Any future changes to the
          software that renders any <b className="col_blue_wood">{CONTACTS.APP_NAME}</b> product redundant or has any
          detrimental effect on the performance of the product, are not the responsibility of{' '}
          <b className="col_blue_wood">{CONTACTS.APP_NAME}</b> and no refunds will be considered.{' '}
          <b className="col_blue_wood">{CONTACTS.APP_NAME}</b> will endeavor to provide upgrades and updates to match
          changes to the Adobe Creative Cloud software, but this is not mandatory and is at the discretion of the
          creator.
        </p>
      </div>
    </div>
  );
};

export default RefundPolicy;
