import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button } from 'components';
import { CartCard } from 'components/_Cards';
import { ConfirmModal } from 'components/_Modals';
import { removeFromCart } from 'store/cartSlice';
import { priceWithDiscount } from 'utils/numberFormat';
import { isEmptyObject } from 'utils/validData';
import { ROUTER_PATHS } from 'constants/enums';
import { ReactComponent as CartIcon } from 'assets/images/cart.svg';
import { FastSprings } from './_Checkouts';

import s from './s.module.scss';

const Payment = () => {
  const dispatch = useDispatch();
  const { cart } = useSelector(({ cartSlice }) => ({
    cart: cartSlice.cart,
  }));

  const [confirmModalState, setConfirmModalState] = useState({});

  const handleCloseConfirmModal = () => {
    setConfirmModalState({});
  };

  const handleRemoveGoodFromCart = (id) => {
    setConfirmModalState({
      question: 'Are you sure you want to delete the product?',
      confirmLabel: 'Delete',
      onConfirm: () => dispatch(removeFromCart(id)),
    });
  };

  const isCartEmpty = !cart?.length;

  const totalAmount = cart?.reduce((acc, { price, discount, count }) => {
    return acc + priceWithDiscount(price, discount) * count;
  }, 0);

  return (
    <>
      <div className="main_container mb40">
        {!isCartEmpty ? (
          <div className={s.cart}>
            <div className={s.goods}>
              {cart.map((good) => (
                <CartCard key={good.id} desc={good} onRemoveGoodFromCart={handleRemoveGoodFromCart} />
              ))}
            </div>
            {/* <TwoCheckout totalAmount={totalAmount} cart={cart} /> */}
            <FastSprings totalAmount={totalAmount} cart={cart} />
          </div>
        ) : (
          <div className={s.empty}>
            <CartIcon />
            <p className={s.empty_text}>Your shopping cart is empty</p>
            <p className={s.empty_sub_text}>You can choose the product you like the most in our shop</p>
            <Link to={ROUTER_PATHS.SHOP}>
              <Button label="Go To Shop" size="lg" round="block" theme="lightBlue" />
            </Link>
          </div>
        )}
      </div>
      <ConfirmModal
        isOpen={!isEmptyObject(confirmModalState)}
        onClose={handleCloseConfirmModal}
        state={confirmModalState}
      />
    </>
  );
};

export default Payment;
