import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { isExpired, decodeToken } from 'react-jwt';

import { useScriptLoad, usePageHeadContent, useFieldsState, useBoolean, useIsMount } from 'hooks';
import { currencyFormat } from 'utils/intlFormat';
import { onLoadFastSpring } from 'utils/scriptLoad';

import s from './s.module.scss';

const getTaxId = (countryCode) => {
  const taxIdByCountry = {
    PT: '207708690',
    ES: 'B12345678',
  };

  if (!countryCode || !taxIdByCountry[countryCode]) {
    return taxIdByCountry.PT;
  }
  return taxIdByCountry[countryCode];
};

const FastSpringPayment = () => {
  usePageHeadContent({ title: 'SimplyADS', faviconName: 'faviconAds' });

  const { token } = useParams();
  const jwtData = useRef();
  const isMounted = useIsMount();

  const [isFastSpringLoaded, setIsFastSpringLoaded] = useBoolean(false);

  const [jwtState, setJwtState] = useFieldsState({ isExpired: true });

  useScriptLoad({
    onLoadCallback: onLoadFastSpring,
    callbackOptions: {
      isNeedOnCloseRedirect: true,
      onMount: setIsFastSpringLoaded.on,
    },
  });

  const handleCheckToken = () => {
    const isTokenExpired = isExpired(token);
    const decodedToken = decodeToken(token);

    if (isTokenExpired || !decodedToken) {
      window.location.href = 'https://www.google.com';
      return;
    }

    setJwtState({
      isExpired: isTokenExpired,
      data: decodedToken,
    });

    jwtData.current = decodedToken;
  };

  const handleOpenFastSpring = () => {
    fastspring.builder.tag({
      amount: jwtState.data.amount,
      userId: jwtState.data.userId,
    });
    fastspring.builder.taxId(getTaxId(jwtState.data.country));
    fastspring.builder.push({
      reset: true,
      products: [{ path: jwtState.data.ids, quantity: 1 }],
      checkout: true,
    });
  };

  const handleCheckout = () => {
    if (!jwtState.data?.ids) {
      return;
    }
    handleOpenFastSpring();
    setJwtState({ isExpired: true });
  };

  useEffect(() => {
    handleCheckToken();
  }, []);

  useEffect(() => {
    if (isMounted || !isFastSpringLoaded || !jwtState.data?.ids || jwtState.isExpired) {
      return;
    }
    handleOpenFastSpring();
  }, [isFastSpringLoaded, jwtState]);

  if (jwtState.isExpired) {
    return null;
  }
  return (
    <div className={s.payment_page}>
      <div className={s.payment_block}>
        <p className={s.amount}>
          Amount: <b className={s.amount_value}>{currencyFormat(jwtState?.data?.amount)}</b>
        </p>
        <button type="button" className={s.pay_btn} onClick={handleCheckout}>
          Pay
        </button>
      </div>
    </div>
  );
};

export default FastSpringPayment;
