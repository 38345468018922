import ReactPaginate from 'react-paginate';

import s from './s.module.scss';

const Pagination = ({ forcePage, totalPages, onChange, pageRange = 3, marginPages = 1, breakLabel = '...' }) => {
  const handlePageClick = ({ selected }) => {
    if (onChange) {
      onChange(selected);
    }
  };

  return (
    <ReactPaginate
      breakLabel={breakLabel}
      forcePage={forcePage}
      onPageChange={handlePageClick}
      pageRangeDisplayed={pageRange}
      marginPagesDisplayed={marginPages}
      pageCount={totalPages}
      className={s.pagination}
      pageClassName={s.page_number}
      activeClassName={s.active_page}
      previousClassName={s.disabled_btn}
      nextClassName={s.disabled_btn}
    />
  );
};

export default Pagination;
